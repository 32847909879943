/* You can add global styles to this file, and also import other style files */
@import 'normalize.css/normalize.css';
@import 'apps/angular-examples/src/styles/app-loading-style';
@import 'apps/angular-examples/src/styles/ws-material-theme';
@import 'apps/angular-examples/src/styles/feature-theming';
@import 'libs/features/lazy/shader-examples/src/globals';

body,
html {
  font-family: Roboto, 'Helvetica Neue', sans-serif;

  * {
    box-sizing: border-box;

    &:before,
    &:after {
      box-sizing: border-box;
    }
  }
}

body {
  margin: 0;
  transition:
    color,
    background-color 500ms ease;
}
