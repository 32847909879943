@use 'sass:map';
@use '@angular/material' as mat;

@mixin theming-with-css-vars($activeColor) {
  app-nav-item {
    --active-color: #{$activeColor};
  }
}

@mixin color($mat-theme) {
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($mat-theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, primary);
  $accent-palette: map.get($color-config, accent);
  $warn-palette: map.get($color-config, warn);

  @include theming-with-css-vars(
    mat.m2-get-color-from-palette($accent-palette, darker)
  );
}

@mixin theme($mat-theme) {
  $color-config: mat.m2-get-color-config($mat-theme);
  @if $color-config != null {
    @include color($mat-theme);
  }

  .active {
    color: #3d99e9;
  }
}
