.app-loading {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .spinner {
    height: 100px;
    width: 100px;
    animation: rotate 2s linear infinite;
    transform-origin: center center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    .path {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      stroke: hsl(300, 24%, 71%);
      animation: dash 1.5s ease-in-out infinite;
      stroke-linecap: round;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke: hsl(300, 24%, 71%);
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke: hsl(180, 51%, 71%);
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke: hsl(300, 24%, 71%);
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
